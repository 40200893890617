<template>
    <footer class="footer" id="footer">
        <div class="container">
            <div class="columns">
                <div class="col">
                    <div class="copy"><p>{{copy}}</p></div>
                    <div class="logo-project">
                        <a href="#">
                            <img alt="concrete block logo" :src="logo">
                        </a>
                    </div>
                </div>
                <div class="col">
                    <a href="https://v-web.kz" target="_blank">
                        <p>{{developer}}</p>
                        <img alt="victory web logo" :src="logo_vweb">
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    // import svgIcon from '@/components/SvgIcon.vue';
    export default {
        name: 'AppFooter',
        data () {
            return {
                logo: require('@/assets/images/logo.svg'),
                logo_vweb: require('@/assets/images/vw-logo.svg'),
                developer: 'Developed by Victory WEB',
                copy: '© 2022, Concrete Reinforced. Все права защищены.',
            }
        },
        components: {
            // svgIcon,
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/footer/footer.scss';
    @import '@/styles/components/footer/footer-respond.scss';
    // @import '@/styles/components/footer/footer-respond.scss';
</style>