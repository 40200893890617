
import { createRouter, createWebHistory} from 'vue-router'

const home = () => import( /* webpackChunkName: "home" */ '@/views/home.vue');
const about = () => import( /* webpackChunkName: "about" */ '@/views/about.vue');
const projects = () => import( /* webpackChunkName: "projects" */ '@/views/projects.vue');
const projectsCard = () => import( /* webpackChunkName: "projectsCard" */ '@/components/projects-page/project-card-page.vue');
const products = () => import( /* webpackChunkName: "products" */ '@/views/products.vue');
const productCard = () => import( /* webpackChunkName: "productCard" */ '@/components/product-page/product-card.vue');
const NotFound = () => import( /* webpackChunkName: "notFound" */ '@/views/404.vue');


const routes = [
    {
        path: '/',
        name: 'home',
        component: home
    },
    {
        path: '/about',
        name: 'about',
        component: about
    },
    {
        path: '/projects',
        name: 'projects',
        component: projects
    },
    {
        path: '/projects/project_:id',
        name: 'projects-card',
        component: projectsCard
    },
    {
        path: '/products',
        name: 'products',
        component: products
    },
    {
        path: '/products/:id',
        name: 'product-card',
        component: productCard
    },
    { 
        path: '/404', 
        name: '404', 
        component: NotFound, 
    }, 
    { 
        path: '/:pathMatch(.*)*', 
        redirect: '/404'
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (from.name == 'projects-card' && to.name == 'projects') {
			return savedPosition
        } else {
			return {left: 0, top: 0}
        }
	},
})

export default router
