import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import LoadScript from "vue-plugin-load-script";
import Maska from 'maska'
import VueSmoothScroll from 'vue3-smooth-scroll'

window.axios = require('axios');
window.$ = window.jQuery = require('jquery');

const app = createApp(App).use(store).use(router);
app.use(LoadScript)
app.use(Maska)
app.use(VueSmoothScroll, {
    updateHistory: false,
    duration: 700,
})
app.mount('#app');