import { createStore } from 'vuex'

export default createStore({
    state: {
        isOpenFormModal: false,
        isOpenModalPromo: false,
        isOpenSendwich: false,
        product_cards: [
            { ///// PRODUCT #1
                id: 'product_1',
                table_second: false,
                section_fourh: false,
                img: require('@/assets/images/samples/product_prev_01.png'),
                title:'Опалубочные панели CONCRETE',
                desc: 'Опалубочная панель CONCRETE – элемент конструкции сборно-монолитного перекрытия впервые использующийся в Казахстане.',
                images: [
                    require('@/assets/images/samples/product_01_pic_main.png'), // Главная картинка
                    require('@/assets/images/samples/product_02_pic.png'), // Центральная картинка
                    require('@/assets/images/samples/product_03_pic.png'), // Секция 3 картинка №1
                    require('@/assets/images/samples/product_04_pic.png'), // Секция 3 картинка №2
                    require('@/assets/images/samples/product_04_pic.png'), // Секция 4 картинка №1
                ],
                desc_top: [
                    'Опалубочная панель CONCRETE – элемент конструкции сборно - монолитного перекрытия впервые использующийся в Казахстане.',
                    'Сборно-монолитные железобетонные перекрытия рекомендованы для применения в строительстве зданий гражданского, общественного и промышленного назначения. '
                ],
                desc_center: [
                    'Основным элементом опалубочной панели CONCRETE являются треугольные арматурные каркасы, имеющие многофункциональное значение:',
                ],
                desc_center_bottom: [
                    'Каркасы, продольная и поперечная арматура укладываются в бетонную плиту таким образом, что нижний пояс каркаса находился в теле плиты, а верхний выступал за её пределы.',
                ],
                desc_center_list: [
                    'придают плитам жесткость при транспортировке и монтаже',
                    'служат опорой для верхней арматуры перекрытия',
                    'обеспечивают совместную работу сборного и монолитного перекрытия',
                ],
                desc_bottom: [
                    'Опалубочные панели CONCRETE изготавливаются в заводских условиях, поставляются на стройплощадку, монтируются и, в заключении, дополняются слоем монолитного железобетона.',
                    'Опалубочные панели CONCRETE имеет ряд преимуществ:',
                ],
                desc_bottom_strong: 'Опалубочные панели производятся в соответствии с действующими нормативными документами и согласно требований, установленных проектной документацией конкретного здания или сооружения, указанных в заказе на изготовление опалубочныых панелей CONCRETE.',
                desc_bottom_list: [
                    'обладают сравнительно лёгким весом и монтируются с «колес»;',
                    'нижняя грань имеет гладкую поверхность;',
                    'в пределах высоты каркаса производится разводка инженерных коммуникаций;',
                    'возможность перекрыть конструкции сложной формы с эркерами и выступами;',
                    'значительно расширяет область применения перекрытий и снимает многие ограничения для архитекторов.'
                ],
                table_title: 'Координационные размеры опалубочных панелей CONCRETE',
                table_names: [
                    'Марка изделия',
                    'Толщина',
                    'Ширина',
                    'Длина',
                    'Вес, т',
                    'Объем, м3'
                ],
                table_items: [
                    {
                        one: 'ОП1-хх.хх',
                        two: '70 мм',
                        three: '900',
                        four: '3 500 ... 6 000',
                        five: '0,55 ... 0,95',
                        six: '0,22 ... 0,38'
                    },
                    {
                        one: '',
                        two: '',
                        three: '1200',
                        four: '',
                        five: '0,73 ... 1,26',
                        six: '0,29 ... 0,50'
                    },
                    {
                        one: '',
                        two: '',
                        three: '1500',
                        four: '',
                        five: '0,92 ... 1,58',
                        six: '0,37 ... 0,63'
                    },
                    {
                        one: 'ОП2-хх.хх',
                        two: '100 мм',
                        three: '900',
                        four: '3 500 ... 7 000',
                        five: '0,79 ... 1,58',
                        six: '0,32 ... 0,63'
                    },
                    {
                        one: '',
                        two: '',
                        three: '1200',
                        four: '',
                        five: '1,05 ... 2,10',
                        six: '0,42 ... 0,84'
                    },
                    {
                        one: '',
                        two: '',
                        three: '1500',
                        four: '',
                        five: '1,31 ... 2,63',
                        six: '0,53 ... 1,05'
                    },
                    {
                        one: '',
                        two: '',
                        three: '1800',
                        four: '',
                        five: '1,58 … 3,15',
                        six: '0,63 … 1,26'
                    }
                ],
                table_names_second: false,
                table_items_second: false,
            },
            { ///// PRODUCT #2
                id: 'product_2',
                table_second: true,
                section_fourh: true,
                img: require('@/assets/images/samples/product_prev_02.png'),
                title:'Наружные стеновые панели CONCRETE',
                desc: 'Железобетонные ненесущие стеновые панели – ограждающие фасадные одно-, трехслойные элементы.',
                images: [
                    require('@/assets/images/samples/product_02_pic_main.png'), // Главная картинка
                    null, // Центральная картинка
                    null, // Секция 3 картинка №1
                    false, // Секция 3 картинка №2
                    null, // Секция 4 картинка №1
                ],
                desc_top: [
                    'Железобетонные ненесущие стеновые панели – ограждающие фасадные одно-, трехслойные элементы предназначены для наружных стен многоэтажных жилых, общественных и производственных зданий.',
                    'Стеновые панели используюутся в сейсмических районах 7-9 баллов. '
                ],
                desc_center: [
                    'Ограждающие фасадные стеновые трехслойные панели с эффективным утеплителем представляют собой трехслойную конструкцию, в которой между железобетонными слоями, соединенных между собой гибкими металлическими связями, расположен слой эффективной комбинированной теплоизоляции из пенополистирола и минераловатных плит из габбро диабаза.',
                    'Стеновые панели изготавливаются из тяжелого бетона с использованием арматуры А500С, А240:'
                ],
                desc_center_bottom: false,
                desc_center_list: [
                    'Класс бетона по прочности не менее С 20/25 (В25);',
                    'Марка по морозостойкости не менее F75;',
                    'Марка по водопроницаемости не менее W4.',
                ],
                desc_bottom: [
                    'Облегченные фасадные стеновые панели на основе металлического каркаса с ребрами жесткости и заполнением утеплителем из минеральной каменной ваты на базальтовой основе производятся согласно СТ ТОО 3871187-03-2009.',
                    'Узлы крепления стеновых панелей выполняются в соответствии с "Иновационным патентом №26091".',
                    'Наружный фактурный слой выполнен из акрилово-цементного клея с армированием стеклотканной сетки.'
                ],
                desc_bottom_list: false,
                desc_bottom_strong: false,
                desc_four_section: [
                    'После монтажа облегченных фасадных стеновых панелей (ПСМ) с внутренней стороны выкладывают стену из теплоблока толщиной 100 – 150мм для лучшей звукоизоляции. Облицовочные стеновые панели (ПСО) монтируются на подсистему.',
                    'Все стеновые панели производятся в соответствии с действующими нормативными документами и согласно требований, установленных проектной документацией конкретного здания и указанных в заказе на изготовление стеновых панелей.'
                ],
                desc_four_section_list: false,
                table_title: 'Наружные стеновые панели CONCRETE',
                table_title_second: 'Облегченные фасадные стеновые панели',
                table_names: [
                    'Марка изделия',
                    'Толщина',
                    'Вес, 1м2',
                    'Сопротивление теплопередаче',
                    'Огне стойкость',
                    'Сейсмо стойкость'
                ],
                table_items: [
                    {
                        one: '1НС –хх.хх Навесная однослойная ж/б панель',
                        two: '120 мм',
                        three: '413,0',
                        four: '1,53',
                        five: 'EI60',
                        six: '9 баллов'
                    },
                    {
                        one: '3НС – хх.хх Трехслойная ж/б панель с min. опиранием на плиту перекрытия',
                        two: '275мм (130мм-утеплитель)',
                        three: '426,0',
                        four: '2,60',
                        five: 'EI60',
                        six: '10 баллов'
                    },
                ],
                table_names_second: [
                    'Марка изделия',
                    'Толщина',
                    'Вес, 1м2',
                    'Сопротивление теплопередаче',
                    'Огне стойкость',
                    'Сейсмо стойкость'
                ],
                table_items_second: [
                    {
                        one: 'ПСМ –хх.хх Навесная панель',
                        two: '150 мм',
                        three: '106,0',
                        four: '2,79',
                        five: 'К0',
                        six: '9 баллов'
                    },
                    {
                        one: 'ПСО – хх.хх Облицовочная  панель',
                        two: '50 мм',
                        three: '16',
                        four: 'Увеличивает на 1,51',
                        five: 'К0',
                        six: '10 баллов'
                    },
                ],
            },
            { ///// PRODUCT #3
                id: 'product_3',
                table_second: false,
                section_fourh: false,
                img: require('@/assets/images/samples/product_prev_03.png'),
                title:'Керамзитобетонные перегородки CONCRETE',
                desc: 'Керамзитобетонные перегородки – внутренние ненесущие стеновые панели, изготовляемые из легкого бетона - кезамзитобетон).',
                images: [
                    require('@/assets/images/samples/product_03_pic_main.png'), // Главная картинка
                    null, // Центральная картинка
                    null, // Секция 3 картинка №1
                    false, // Секция 3 картинка №2
                    null, // Секция 4 картинка №1
                ],
                desc_top: [
                    'Керамзитобетонные перегородки – внутренние ненесущие стеновые панели, изготовляемые из легкого бетона – кезамзитобетон.',
                    'Данный продукт предназначен для внутренних ненесущих стен и перегородок надземных этажей многоэтажных жилых и общественных зданий'
                ],
                desc_center: [
                    'Перегородки изготавливаются из керамзитобетона плотностью 1 800 кг/м3 с использованием арматуры  А240 и В500.',
                    'Межкомнатные стеновые перегородки из керамзитобетонных панелей относятся к группе негорючих материалов.',
                    'Данный продукт обладает следующими свойствами:',
                ],
                desc_center_bottom: false,
                desc_center_list: false,
                desc_bottom: [
                    'В торцевых зонах панели имеют вырезы и углубления для примыкания к панелям смежных конструктивных элементов, предназначенных для образования шпоночного соединения после замоно-личивания стыков.',
                ],
                desc_bottom_list: false,
                desc_bottom_strong: false,
                desc_four_section: false,
                desc_four_section_list: false,
                table_title: 'Координационные размеры панелей*',
                table_title_second: false,
                table_names: [
                    'Марка изделия',
                    'Толщина',
                    'Ширина',
                    'Длина',
                    'Вес, 1м2',
                    'Объем, м3'
                ],
                table_items: [
                    {
                        one: 'ПГ1 –хх.хх Панель перегородочная',
                        two: '100 мм',
                        three: '600',
                        four: '2 500 … 4 000',
                        five: '0,27 ... 0,43',
                        six: '0,15 ...0,24'
                    },
                    {
                        one: 'ПГ2 – хх.хх Панель перегородочная',
                        two: '130 мм',
                        three: '500',
                        four: '2 500 … 4 000',
                        five: '0,29 … 0,47',
                        six: '0,16 … 0,26'
                    },
                ],
                table_names_second: false,
                table_items_second: false,
            },
            { ///// PRODUCT #4
                id: 'product_4',
                table_second: true,
                section_fourh: true,
                img: require('@/assets/images/samples/product_prev_04.png'),
                title:'Лестничные марши с площадками и без',
                desc: 'Элементы лестниц из тяжелого бетона для устройства в зданиях различного назначения, с сейсмичностью 7-9 баллов.',
                images: [
                    require('@/assets/images/samples/product_04_pic_main.png'), // Главная картинка
                    require('@/assets/images/samples/product_03_part_01.png'), // Центральная картинка
                    null, // Секция 3 картинка №1
                    false, // Секция 3 картинка №2
                    require('@/assets/images/samples/product_03_part_02.png'), // Секция 4 картинка №1
                ],
                desc_top: [
                    'Элементы лестниц – железобетонные марши, железобетонные марши с полуплощадками, изготавливаемые из тяжелого бетона, предназначены для устройства лестниц в зданиях различного назначения.',
                    'С расчетной сейсмичностью 7-9 баллов. '
                ],
                desc_center: [
                    'Высота ступени 150 и 160мм.',
                    'Наличие различных видов опалубок позволяет использовать железобетонные марши для зданий с высотами этажей 2.70 м, 3.00 м, 3.10 м, 3.20 м и 3.30 м соответсвенно.',
                    'Имеют следующие характеристики:'
                ],
                desc_center_bottom: false,
                desc_center_list: [
                    'Марка тяжелого бетона С 25/30 (В30);',
                    'Расчетная временная нагрузка 480 кгс/м3;',
                    'Предел огнестойкости не менее 1 час.',
                ],
                desc_bottom: [
                    'Железобетонные марши (ЛМхх-хх-с) – плоские без фризовых ступеней – изготавливают с гладкой поверхностью лицевых граней на обычном цементе, подготовленные под покраску.',
                ],
                desc_bottom_list: false,
                desc_bottom_strong: false,
                desc_four_section: [
                    'Железобетонные марши с полуплощадками (ЛМП-хх.хх.хх-5-с) – плоские без фризовых ступеней с двумя полуплощадками – изготавливают с гладкой поверхностью лицевых граней на обычном цементе, подготовленные под покраску.',
                    'Марши, при необходимости, изготовляют в двух вариантах исполнения: правом и левом - для лестниц с подъемом против хода и по ходу часовой стрелки соответственно.',
                    'Длина полуплощадки 1.30 м.'
                ],
                desc_four_section_list: false,
                table_title: 'Железобетонные марши (ЛМхх-хх-с) – плоские без фризовых ступеней',
                table_title_second: 'Железобетонные марши с полуплощадками (ЛМП-хх.хх.хх-5-с) – с двумя полуплощадками ',
                table_names: [
                    'Марка изделия',
                    'Габариты',
                    'Вес, т',
                    'Объем, м3',
                ],
                table_items: [
                    {
                        one: 'ЛМ27-11-с',
                        two: '3240х1350х1100',
                        three: '2,32',
                        four: '0,9',
                    },
                    {
                        one: 'ЛМ30-11-с',
                        two: '3540х1500х1100',
                        three: '2,58',
                        four: '1,0',
                    },
                    {
                        one: 'ЛМ32-11-с',
                        two: '3540х1600х1100',
                        three: '2,60',
                        four: '1,01',
                    },
                    {
                        one: 'ЛМ33-11-с',
                        two: '3840х1650х1100',
                        three: '2,78',
                        four: '1,08',
                    },
                    {
                        one: 'ЛМ33-12-с',
                        two: '3840х1650х1200',
                        three: '3,07',
                        four: '1,19',
                    },
                ],
                table_names_second: [
                    'Марка изделия',
                    'Габариты',
                    'Вес, т',
                    'Объем, м3',
                ],
                table_items_second: [
                    {
                        one: 'ЛМП-50.11.14-5-с',
                        two: '5000х1350х1100',
                        three: '3,46',
                        four: '1,31',
                    },
                    {
                        one: 'ЛМП-53.11.15-5-с',
                        two: '5300х1500х1100',
                        three: '3,68',
                        four: '1,40',
                    },
                    {
                        one: 'ЛМП-53.11.16-5-с',
                        two: '5300х1600х1100',
                        three: '3,76',
                        four: '1,43',
                    },
                    {
                        one: 'ЛМП-56.11.17-5-с',
                        two: '5600х1650х1100',
                        three: '3,94',
                        four: '1,50',
                    },
                    {
                        one: 'ЛМП-56.12.17-5-с',
                        two: '5600х1650х1200',
                        three: '4,45',
                        four: '1,70',
                    },
                ],
            },
            { ///// PRODUCT #5
                id: 'product_5',
                table_second: false,
                section_fourh: false,
                img: require('@/assets/images/samples/product_prev_05.png'),
                title:'Колонны постоянного поперечного сечения',
                desc: 'Железобетонные колонны постоянного поперечного сечения 400х400, 500х500 - элемент сборной конструкции каркасов.',
                images: [
                    require('@/assets/images/samples/product_05_pic_main.png'), // Главная картинка
                    require('@/assets/images/samples/product_05_part_01.png'), // Центральная картинка
                    require('@/assets/images/samples/product_05_part_02.png'), // Секция 3 картинка №1
                    false, // Секция 3 картинка №2
                    false, // Секция 4 картинка №1
                ],
                desc_top: [
                    'Железобетонные колонны постоянного поперечного сечения 400х400, 500х500 - элемент сборной конструкции каркасов многоэтажных общественных зданий, производственных, административных и бытовых зданий промышленных предприятий, подземных паркингов.',
                    'Расчетная сейсмичность 7-9 баллов.'
                ],
                desc_center: [
                    'Колонны изготавливаются из тяжелого бетона с использованием арматуры А500С, А240.',
                    'Основные характеристики данного продукта:',
                ],
                desc_center_bottom: false,
                desc_center_list: [
                    'Класс бетона по прочности не менее С 20/25 (В25);',
                    'Марка по морозостойкости не менее F75;',
                    'Марка по водопроницаемости не менее W4.',
                ],
                desc_bottom: [
                    'Опалубочные конструкции для железобетонных колонн постоянного поперечного сечения 400х400, 500х500 длиной 10 м позволяют производить одно-, двух-, трехэтажные в пределах высоты колонны.',
                    'Колонны производятся в соответствии с действующими нормативными документами и согласно требований, установленных проектной документацией конкретного здания или сооружения, указанных в заказе на изготовление колонн.',
                    'Железобетонные колонны сплошного прямоугольного поперечного сечения (с консолями и без) предназначены для каркасов одноэтажных зданий предприятий всех отраслей промышленности и сельского хозяйства.',
                    'Колонны сечением 400х700 и 500х800 высотой до 13,0м производятся согласно требований нормативных документов, действующих серий в РК, указанных в проектной документации конкретного здания.'
                ],
                desc_bottom_list: false,
                desc_bottom_strong: false,
                desc_four_section: false,
                desc_four_section_list: false,
                table_title: 'Железобетонные марши (ЛМхх-хх-с) – плоские без фризовых ступеней',
                table_title_second: false,
                table_names: [
                    'Марка изделия',
                    'Габариты,мм  ВхНхL',
                    'Вес, т',
                    'Объем, м3',
                ],
                table_items: [
                    {
                        one: '1К-4.хх-с',
                        two: '400х400х3 500 ... 10 000',
                        three: '1,40 ... 4,00',
                        four: '0,56 ... 1,60',
                    },
                    {
                        one: '1К-5.хх-с',
                        two: '500х500х3 500 ... 10 000',
                        three: '2,19 ... 6,25',
                        four: '0,88 ... 1,60',
                    },
                ],
                table_names_second: false,
                table_items_second: false,
            },
            { ///// PRODUCT #6
                id: 'product_6',
                table_second: false,
                section_fourh: false,
                img: require('@/assets/images/samples/product_prev_06.png'),
                title:'Фундаменты стаканного типа',
                desc: 'Железобетонный фундамент стаканного типа – основной элемент несущей конструкции.',
                images: [
                    require('@/assets/images/samples/product_06_pic_main.png'), // Главная картинка
                    null, // Центральная картинка
                    null, // Секция 3 картинка №1
                    false, // Секция 3 картинка №2
                    false, // Секция 4 картинка №1
                ],
                desc_top: [
                    'Железобетонный фундамент стаканного типа – основной элемент несущей конструкции многоэтажных каркасно-панельных общественных зданий, производственных, административных и бытовых зданий промышленных предприятий, подземных паркингов.',
                    'С расчетной сейсмичностью 7-9 баллов.  '
                ],
                desc_center: [
                    'Монолитный железобетонный фундамент (1Ф, 2Ф) под ж/б колонны сечением 400х400 и 500х500 состоит из плитной части и подколонника. Плитная часть – одноступенчатая, верх подколонника принимается на отм.-0.15 м. ',
                    'Сопряжение колонны с фундаментом выполняется.',
                    'Фундаменты изготавливаются из тяжелого бетона с использованием арматуры А500С, А240.'
                ],
                desc_center_bottom: false,
                desc_center_list: [
                    'Класс бетона по прочности не менее С 20/25 (В25);',
                    'Марка по морозостойкости не менее F75;',
                    'Марка по водопроницаемости не менее W4.',
                ],
                desc_bottom: [
                    'Монолитный железобетонный фундамент (3Ф) под металлические стойки фахверка состоит из плитной части и подколонника. Плитная часть – одноступенчатая, верх подколонника принимается на отм.-0.15 м.',
                    'Сопряжение колонны с фундаментом выполняется шарнирным.',
                    'Фундаменты производятся в соответствии с действующими нормативными документами и согласно требований, установленных проектной документацией конкретного здания или сооружения, указанных в заказе на изготовление фундаменты.',
                ],
                 




                desc_bottom_list: false,
                desc_bottom_strong: false,
                desc_four_section: false,
                desc_four_section_list: false,
                table_title: 'Основные параметры и размеры хФ-хх.хх.х-с (А500С В25)',
                table_title_second: false,
                table_names: [
                    'Марка изделия',
                    'Габариты,мм  ВхНхL',
                    'Вес, т',
                    'Объем, м3',
                ],
                table_items: [
                    {
                        one: '1Ф-20.16.4-с',
                        two: '2000х2000х1600(900х900)',
                        three: '7,43',
                        four: '2,97',
                    },
                    {
                        one: '2Ф-20.16.5-с',
                        two: '2000х2000х1600(1000х1000)',
                        three: '7,65',
                        four: '3,06',
                    },
                    {
                        one: '3Ф-20.16-с',
                        two: '2000х2000х1600(фахверк)',
                        three: '8,03',
                        four: '3,21',
                    },
                ],
                table_names_second: false,
                table_items_second: false,
            },
        ],
        projects_items: [
            {
                id: 1,
                img: require('@/assets/images/samples/project_card_01.png'),
                title: 'Название данного проектаc реализованного компанией в несколько строчек',
                images: [
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                ],
            },
            {
                id: 2,
                img: require('@/assets/images/samples/project_card_02.png'),
                title: 'Название данного крутого проекта',
                images: [
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                ],
            },
            {
                id: 3,
                img: require('@/assets/images/samples/project_card_03.png'),
                title: 'Название данного проекта реализованного компанией',
                images: [
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                ],
            },
            {
                id: 4,
                img: require('@/assets/images/samples/project_card_04.png'),
                title: 'Название данного проекта реализованного компанией в несколько строчек',
                images: [
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                ],
            },
            {
                id: 5,
                img: require('@/assets/images/samples/project_card_05.png'),
                title: 'Название данного крутого проекта',
                images: [
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                ],
            },
            {
                id: 6,
                img: require('@/assets/images/samples/project_card_06.png'),
                title: 'Название данного проекта реализованного компанией',
                images: [
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                    require('@/assets/images/samples/project_card_img.png'),
                ],
            },
        ]
    },
    getters: {
        loadOpenFormModal: state => state.isOpenFormModal,
        loadOpenModalPromo: state => state.isOpenModalPromo,
        loadOpenSendwich: state => state.isOpenSendwich,
        //
        loadProjectItems: state => state.projects_items,
        loadProductCards: state =>  state.product_cards,
    },
    actions: {
    },
    mutations: {
        Open_form_modal(state, data) {
            state.isOpenFormModal = data
        },
        Open_promo_modal(state, data) {
            state.isOpenModalPromo = data
        },
        Open_Sendwich(state, data) {
            state.isOpenSendwich = data
        },
    },
    modules: {
    }
})
