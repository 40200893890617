<template>
    <div class="wrapper">
        <div class="wrapper-in">
            <AppHeader v-if="!iSmobile"></AppHeader>
            <mobileSidebar></mobileSidebar>
            <main class="content" id="content-layout">
                <router-view :key="$route.fullPath"/>
            </main>
            <AppFooter></AppFooter>
            <transition
                name="fade"
                mode="out-in"
            >
                <div class="body-overlay-mobile" id="body-overlay" @click="closeMobileMenu()" v-cloak v-if="openSendwich"></div>
            </transition>
        </div>
    </div>
</template>

<script>
    import AppHeader from '@/components/header/header.vue'
    import AppFooter from '@/components/footer/footer.vue'
    import mobileSidebar from '@/components/nav-main/mobile-sidebar.vue'
    import {mapGetters} from 'vuex'
    export default {
        components: {
            AppHeader,
            AppFooter,
            mobileSidebar,
        },
        data () {
            return {
                iSmobile: false,
                yandexNoScript: null,
            }
        },
        computed: mapGetters({
            isOpenModalPromo: 'loadOpenModalPromo',
            openSendwich: 'loadOpenSendwich',
        }),
        methods: {
            closeMobileMenu() {
                this.$store.commit('Open_Sendwich', false);
                document.getElementsByTagName('body')[0].classList.remove('overlay-open');
            },
            // // MOBILE CHECKER
            // isMobileCheck() {
            //     if (window.innerWidth < 767) {
            //         this.iSmobile = true;
            //     }
            // },
            // handleResize() {
            //     if (window.innerWidth < 767) {
            //         this.iSmobile = true
            //     } else {
            //         this.iSmobile = false
            //     }
            // },
            // checkResize() {
            //     window.addEventListener('resize', this.handleResize)
            //     this.handleResize();
            // },
            // setNonScriptToBody() {
            //     this.yandexNoScript = '88746674';
            // },
        },
        created () {
            // this.setNonScriptToBody();
        },
        mounted () {
            // this.isMobileCheck();
            // this.checkResize();
        }
    }
</script>