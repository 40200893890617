<template>
    <header class="header" id="header">
        <div class="container">
            <div class="logo-main">
                <router-link to="/">
                    <img alt="concrete reinforced logo" :src="logo">
                </router-link>
            </div>
            <div class="header-navmain">
                <navMain class="header-navigation"></navMain>
            </div>
            <div class="corp-links">
                <ul>
                    <li>
                        <a href="#contacts-section" v-smooth-scroll>
                            <svgIcon name="phone02" class="svg-icon icon-phone"></svgIcon>
                            <p>{{phone}}</p>
                        </a>
                    </li>
                    <li>
                        <a :href="'mailto:'+mail">
                            <svgIcon name="mail02" class="svg-icon icon-mail"></svgIcon>
                            <p>{{mail}}</p>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="logo-corp">
                <a href="https://concrete.kz" target="_blank">
                    <img alt="concrete.kz" :src="logo_cp">
                </a>
            </div>
            <div class="mobile-menu-btn" :class="{open: openSendwich}" @click="openMobileMenu(!openSendwich)"><span /></div>
        </div>
    </header>
</template>

<script>
    import {mapGetters} from 'vuex'
    import svgIcon from '@/components/partials/SvgIcon.vue';
    import navMain from '@/components/nav-main/nav-main.vue'
    export default {
        name: 'AppHeader',
        components: {
            svgIcon,
            navMain,
        },
        data () {
            return {
                logo: require('@/assets/images/logo.svg'),
                logo_cp: require('@/assets/images/cp-logo.svg'),
                phone: '+7 727 311-83-46',
                mail: 'rc@concrete.kz',
            }
        },
        computed: mapGetters({
            openSendwich: 'loadOpenSendwich',
        }),
        methods: {
            openMobileMenu(data) {
                this.$store.commit('Open_Sendwich', data);
                document.getElementsByTagName('body')[0].classList.toggle('overlay-open');
            },
        },
        beforeCreate() {
        },
        created () {
        },
        mounted () {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/header/header.scss';
    @import '@/styles/components/header/header-respond.scss';
</style>