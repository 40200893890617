<template>
    <div class="nav-main">
        <ul>
            <li class="parent-link">
                <a href="/products" @click.prevent>ПРОДУКЦИЯ</a>
                <div class="dropdown-menu">
                    <ul>
                        <li v-for="(item, index) in nav_drop" :key="index">
                            <router-link :to="{name: 'product-card' , params:{'id' : item.link}}">{{item.name}}</router-link>
                        </li>
                    </ul>
                </div>
            </li>
            <li v-for="(item, index) in nav" :key="index">
                <router-link :to="item.link">{{item.name}}</router-link>
            </li>
            <li>
                <a href="#contacts-section" v-smooth-scroll>КОНТАКТЫ</a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'nav-main',
        components: {
        },
        data () {
            return {
                nav: [
                    {
                        name: 'О КОМПАНИИ',
                        link:'/about',
                    },
                    {
                        name: 'ПРОЕКТЫ',
                        link:'/projects',
                    },
                ],
                nav_drop: [
                    {
                        name: 'Опалубочная панель',
                        link:'product_1',
                    },
                    {
                        name: 'Наружные стеновые панели',
                        link:'product_2',
                    },
                    {
                        name: 'Керамзитобетонные перегородки',
                        link:'product_3',
                    },
                    {
                        name: 'Лестничные марши',
                        link:'product_4',
                    },
                    {
                        name: 'Колонны',
                        link:'product_5',
                    },
                    {
                        name: 'Фундаменты стаканного типа',
                        link:'product_6',
                    },
                ]
            }
        },
    }
</script>

<style lang="scss">
    @import '@/styles/components/nav-main/nav-main.scss';
</style>