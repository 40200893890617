<template>
    <div class="mobile-sidebar" :class="{open: openSendwich}">
        <div class="mobile-nav-main">
            <ul>
                <li class="parent-link" :class="{'open' : openSlideDown}">
                    <a href="/products" @click.prevent="updateSlideDown(!openSlideDown)">
                        <span>ПРОДУКЦИЯ</span>
                        <div class="nav-icon"></div>
                    </a>
                    <slide-up-down v-model="openSlideDown" :duration="400" class="dropdown-menu">
                        <ul>
                            <li v-for="(item, index) in nav_drop" :key="index">
                                <router-link :to="{name: 'product-card' , params:{'id' : item.link}}" @click="closeMobileMenu">{{item.name}}</router-link>
                            </li>
                        </ul>
                    </slide-up-down>
                </li>
                <li v-for="(item, index) in nav" :key="index">
                    <router-link :to="item.link" @click="closeMobileMenu">{{item.name}}</router-link>
                </li>
                <li>
                    <a href="#contacts-section" v-smooth-scroll @click="closeMobileMenu">КОНТАКТЫ</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import SlideUpDown from 'vue3-slide-up-down'
    import {mapGetters} from 'vuex'
    export default {
        name: 'mobile-nav-main',
        data () {
            return {
                openSlideDown: false,
                regionCity: null,
                nav: [
                    {
                        name: 'О КОМПАНИИ',
                        link:'/about',
                    },
                    {
                        name: 'ПРОЕКТЫ',
                        link:'/projects',
                    },
                ],
                nav_drop: [
                    {
                        name: 'Опалубочная панель',
                        link:'product_1',
                    },
                    {
                        name: 'Наружные стеновые панели',
                        link:'product_2',
                    },
                    {
                        name: 'Керамзитобетонные перегородки',
                        link:'product_3',
                    },
                    {
                        name: 'Лестничные марши',
                        link:'product_4',
                    },
                    {
                        name: 'Колонны',
                        link:'product_5',
                    },
                    {
                        name: 'Фундаменты стаканного типа',
                        link:'product_6',
                    },
                ]
            }
        },
        components: {
            SlideUpDown
        },
        computed: mapGetters({
            openSendwich: 'loadOpenSendwich',
        }),
        methods: {
            updateSlideDown(param) {
                this.openSlideDown = param;
            },
            closeMobileMenu() {
                this.$store.commit('Open_Sendwich', false);
                document.getElementsByTagName('body')[0].classList.remove('overlay-open');
            },
        },
        created() {
        },
        mounted() {
        }
    }
</script>

<style lang="scss">
    @import '@/styles/components/nav-main/mobile-sidebar.scss';
</style>